<template>
    <div class="flex column gaps">
        <app-home-sessions></app-home-sessions>

        <div class="box flex gaps">
          <div class="box">
            <h4 class="mb-5 o-050">Search Clients: </h4>
          </div>
          <div class="box grow">
            <el-input class="box grow" ref="search" v-model="search"></el-input>
          </div>
        </div>
        <div class="box">
          <vue-scroll class="scroll-area-members flex" :settings="settings" :style="{height: scrollHeight + 'px'}">
            <div class="box grow">
              <app-waiver-row
                :item="c"
                :isChecked="c.waiver"
                :isHome="true"
                v-for="c in filtered" :key="c._id +'-waiver'"></app-waiver-row>
            </div>
          </vue-scroll>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppWaiverRow from './components/WaiverRow'
import AppHomeSessions from './components/HomeSessions'

export default {
  name: 'Home',

  components: {
    AppWaiverRow,
    AppHomeSessions
  },

  props: {
    clearInputHome: {
      type: Boolean,
      default: false
    },
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      search: '',
      settings: {
        maxScrollbarLength: 60
      }
    }
  },

  computed: {
    ...mapState({
      lastMembers: state => state.members.lastMembers,
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'basketWaiverIds',
      'lastMembersIds'
    ]),
    filtered () {
      return this.members.filter(item => {
        return (this.basketWaiverIds.includes(item._id)) ||
         (this.lastMembersIds.includes(item._id)) ||
         (this.search.length >= 3 &&
         ((item.hasOwnProperty('first') && item.first.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('surname') && item.surname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('email') && item.email.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
         (item.hasOwnProperty('phone') && item.phone.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)))
      }).map(m => {
        if (this.basketWaiverIds.includes(m._id)) {
          return Object.assign({}, m, { waiver: true })
        } else {
          return Object.assign({}, m, { waiver: false })
        }
      })
    },
    scrollHeight () {
      return this.innerHeight - 452
    }
  },

  methods: {
    searchFocus () {
      this.search = ''
      if (JSON.parse(localStorage.getItem('cursor'))) {
        this.$refs.search.focus()
      }
    }
  },
  activated: async function () {
    this.searchFocus()
  },
  watch: {
    clearInputHome: function (val, oldVal) {
      this.searchFocus()
    }
  }
}
</script>
