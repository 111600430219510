var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box flex gaps grid-12"},[_c('div',{staticClass:"col-6",on:{"click":_vm.clickCurrentSession}},[_c('el-card',{staticClass:"clickable",class:{
            'locked-panel': _vm.currentSessionIndex === -1,
            'locked-warning-panel': _vm.currentSessionIndex !== -1 &&
              _vm.isBlock(_vm.todaySessions[_vm.currentSessionIndex])
          }},[_c('div',{staticClass:"flex column"},[_c('div',{staticClass:"box grow center"},[_c('h3',{staticClass:"success-text mt-5"},[_vm._v("Current Session")])]),_c('div',{staticClass:"box grow flex justify-space-between ph-20"},[_c('div',{staticClass:"box o-050"},[_vm._v(_vm._s(_vm.currentSession.time))]),_c('div',{staticClass:"box",class:_vm.capacityColor(_vm.currentSession.left)},[_vm._v(" "+_vm._s(_vm.currentSession.left)+" / "+_vm._s(_vm.currentSession.capacity)+" ")])])])])],1),_c('div',{staticClass:"col-6",on:{"click":_vm.clickNextSession}},[_c('el-card',{staticClass:"clickable",class:{
            'locked-panel': _vm.nextSessionIndex === -1,
            'locked-warning-panel': _vm.nextSessionIndex !== -1 &&
              _vm.isBlock(_vm.todaySessions[_vm.nextSessionIndex])
          }},[_c('div',{staticClass:"flex column"},[_c('div',{staticClass:"box grow center"},[_c('h3',{staticClass:"success-text mt-5"},[_vm._v("Next Session")])]),_c('div',{staticClass:"box grow flex justify-space-between ph-20"},[_c('div',{staticClass:"box o-050"},[_vm._v(_vm._s(_vm.nextSession.time))]),_c('div',{staticClass:"box",class:_vm.capacityColor(_vm.nextSession.left)},[_vm._v(" "+_vm._s(_vm.nextSession.left)+" / "+_vm._s(_vm.nextSession.capacity)+" ")])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }