<template>
    <div class="box flex gaps grid-12">
      <div class="col-6" @click="clickCurrentSession">
          <el-card class="clickable"
            :class="{
              'locked-panel': currentSessionIndex === -1,
              'locked-warning-panel': currentSessionIndex !== -1 &&
                isBlock(todaySessions[currentSessionIndex])
            }">
              <div class="flex column">
                  <div class="box grow center">
                      <h3 class="success-text mt-5">Current Session</h3>
                  </div>
                  <div class="box grow flex justify-space-between ph-20">
                      <div class="box o-050">{{currentSession.time}}</div>
                      <div class="box" :class="capacityColor(currentSession.left)">
                        {{currentSession.left}} / {{currentSession.capacity}}
                      </div>
                  </div>
              </div>
          </el-card>
      </div>
      <div class="col-6" @click="clickNextSession">
          <el-card class="clickable"
            :class="{
              'locked-panel': nextSessionIndex === -1,
              'locked-warning-panel': nextSessionIndex !== -1 &&
                isBlock(todaySessions[nextSessionIndex])
            }">
              <div class="flex column">
                  <div class="box grow center">
                      <h3 class="success-text mt-5">Next Session</h3>
                  </div>
                  <div class="box grow flex justify-space-between ph-20">
                      <div class="box o-050">{{nextSession.time}}</div>
                      <div class="box" :class="capacityColor(nextSession.left)">
                        {{nextSession.left}} / {{nextSession.capacity}}
                      </div>
                  </div>
              </div>
          </el-card>
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WaiwerRow',

  data () {
    return {}
  },

  computed: {
    ...mapGetters([
      'currentSessionIndex',
      'todaySessions'
    ]),
    nextSessionIndex () {
      if (this.currentSessionIndex + 1 < this.todaySessions.length && this.currentSessionIndex !== -1) {
        return this.currentSessionIndex + 1
      } else {
        return -1
      }
    },
    currentSession () {
      return this.currentSessionIndex !== -1 ? this.todaySessions[this.currentSessionIndex] : ''
    },
    nextSession () {
      return this.nextSessionIndex !== -1 ? this.todaySessions[this.nextSessionIndex] : ''
    }
  },

  methods: {
    ...mapActions(['createBasketItemTicketDefault']),
    capacityColor (capacity) {
      let color
      switch (true) {
        case capacity <= 10:
          color = 'danger-text'
          break
        case capacity >= 11 && capacity <= 20:
          color = 'warning-text'
          break
        default:
          color = 'success-text'
      }
      return color
    },
    clickCurrentSession () {
      if (this.currentSessionIndex !== -1 &&
        !this.isBlock(this.todaySessions[this.currentSessionIndex])) {
        this.createBasketItemTicketDefault({ sessionIdx: this.currentSessionIndex })
      }
    },
    clickNextSession () {
      if (this.nextSessionIndex !== -1 &&
        !this.isBlock(this.todaySessions[this.nextSessionIndex])) {
        this.createBasketItemTicketDefault({ sessionIdx: this.nextSessionIndex })
      }
    },
    isBlock (item) {
      return item.hasOwnProperty('block') &&
        item.block.hasOwnProperty('isBlock') &&
        item.block.isBlock
    }
  }
}
</script>
