<template>
  <div class="flex column mr-20">
    <div class="flex">
      <div class="box align-vertical pr-10">
        <el-switch :value="value" @click.native="onClickSwitch(item._id)" ></el-switch>
      </div>
      <div class="info box grow flex clickable"  @click="onClickWaiver(item._id)">
        <div class="name box grow flex column justify-center p-10">
          <div class="fullname fs-18"
            :class="[{'success-light-text' : item.hasOwnProperty('isChild') && item.isChild}]">
            <strong>{{item.first}} {{item.surname}}</strong>
          </div>
        </div>
        <div class="box grow flex column justify-space-around"
          v-if="item.hasOwnProperty('isChild') && item.isChild">
          <div class="box fs-14 secondary-text">{{howOldAreYou(item.birthday)}} y.o.</div>
          <div class="box fs-14 secondary-text">{{item.parentName || ''}}</div>
        </div>
        <div class="box grow flex column justify-space-around" v-else>
          <div class="box fs-14 secondary-text">{{item.phone}}</div>
          <div class="box fs-14 secondary-text">{{item.email}}</div>
        </div>
      </div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'WaiwerRow',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isChecked: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    isHome: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },

  data () {
    return {}
  },

  computed: {
    value: {
      get () {
        return this.isChecked
      },
      set (newItem) {
        return newItem
      }
    }
  },

  methods: {
    ...mapActions(['addWaiwerToBasket']),
    howOldAreYou (date) {
      let birthday = +new Date(date)
      return ~~((Date.now() - birthday) / (31557600000))
    },
    onClickWaiver (id) {
      if (this.isHome) {
        this.addWaiwerToBasket({ code: id, isRemove: true })
      } else {
        this.$onCommandParams('waiver', { id })
      }
    },
    onClickSwitch (id) {
      this.addWaiwerToBasket({ code: id, isRemove: true })
    }
  }
}
</script>
